import UserLoginSessionService from "__services/users/UserLoginSessionService";
import {CLEAN_TOKEN, LOGIN_USER, SET_CURRENT_USER} from "../../mutations-types";
import EventBus from "../../../event-bus/EventBus";

export default {
	login: (context, payload) => {
		return new Promise((resolve, reject) => {
			UserLoginSessionService.login(payload.username, payload.password)
				.then(pay => {
					context.commit(LOGIN_USER, pay);
					EventBus.$emit(EventBus.types.LOGIN);
					resolve(pay);
				})
				.catch(error => {
					context.commit(CLEAN_TOKEN);
					localStorage.removeItem('authToken');
					reject(error);
				});
		})

	},
	googleLogin: (context, googleToken) => {
		return UserLoginSessionService.googleLogin(googleToken)
			.then(payload => {
				context.commit(LOGIN_USER, payload);
				EventBus.$emit(EventBus.types.LOGIN);
			})
			.catch(error => {
				context.commit(CLEAN_TOKEN);
				localStorage.removeItem('authToken');
			});
	},
	logout: (context, payload = {}) => {
		if(payload.hasOwnProperty('automatic') && payload.automatic === true) {
			context.commit(CLEAN_TOKEN);
			localStorage.removeItem('authToken');
			EventBus.$emit(EventBus.types.LOGOUT);
		} else {
			UserLoginSessionService.logout().then(() => {
				context.commit(CLEAN_TOKEN);
				localStorage.removeItem('authToken');
				EventBus.$emit(EventBus.types.LOGOUT);
			});
		}
	},
	getMe: (context) => {
		return UserLoginSessionService.getMe()
			.then(response => {
				context.commit(SET_CURRENT_USER, response.data);
			}).catch(error => {
				context.commit(CLEAN_TOKEN);
				localStorage.removeItem('authToken');
			});
	}

}
