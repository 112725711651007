<template>
	<search-result-item :to="{ name: 'issueDirectory', params: { id: directory.id } }">
		<template v-slot:avatar>
			<v-icon :size="30" class="mr-4 ml-2">mdi-folder</v-icon>
		</template>
		<template v-slot:name>{{ name }}</template>
		<template v-slot:subline>
			<v-chip outlined v-if="parent">{{ folderName }}</v-chip>
		</template>
	</search-result-item>
</template>

<script>
import SearchResultItem from './SearchResultItem';

export default {
	name: 'SearchResultItemDirectory',
	components: {
		SearchResultItem
	},
	props: ['directory'],
	computed: {
		name: function() {
			return this.directory.name;
		},
        parent: function () {
            return this.$store.getters['issues/getDirectory'](this.directory.parent);
        },
        folderName: function() {
            return  this.parent.name;
        }
    },
    created: function () {
        if(this.directory.parent) {
            this.$store.cache.dispatch('issues/loadDirectory',{id: this.directory.parent});
        }
	}
};
</script>

<style>
</style>
