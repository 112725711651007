<template>
	<v-avatar :color="color" :size="size">
		<span v-if="hasSolvers" class="white--text body-2">{{firstNameChar}}</span>
		<v-icon v-else color="white" :size="size / 2">mdi-account-plus</v-icon>
	</v-avatar>
</template>

<script>
    export default {
        name: "IssueAvatar",
		props: {
            size: {
                type: Number,
				default: 30
			},
			color: {
                type: String,
				default: "grey"
			},
			issue: {

			}
		},
		computed: {
            hasSolvers: function(){
              	return !!this.issue.solvers && this.issue.solvers.length > 0;
			},
            firstNameChar: function() {
                return this.issue.solvers[0].first_name[0].toUpperCase() + this.issue.solvers[0].last_name[0].toUpperCase();
            }
		}
    }
</script>
