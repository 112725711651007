import Vue from 'vue';
import EVENT_TYPES from './eventTypes';

const EventBus = new Vue({
	data() {
		return {
			types: EVENT_TYPES
		};
	}
});

// Definice $event proměnné ve Vue prototype abyste mohli používat this.$event v komponentách
Object.defineProperties(Vue.prototype, {
	$event: {
		get: function() {
			return {
				$on: (event, callback) => {
					return EventBus.$on(event, callback);
				},
				$emit: (eventName, ...args) => {
					return EventBus.$emit(eventName, ...args);
				},
				$off: (event, callback) => {
					return EventBus.$off(event, callback);
				},
				$once: (event, callback) => {
					return EventBus.$once(event, callback);
				},
				types: EVENT_TYPES
			};
		}
	}
});

export default EventBus;
