import Vue from 'vue'
import Axios from "axios";
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import {base} from './config/base';

const sentryDNS = 'https://23526f3240e34f3dae190cce50a16ad1@sentry.olc.cz/29';
let dns = false;

if (base.VUE_APP_DISABLE_SENTRY !== 'true') {
	dns = sentryDNS
}
Sentry.init({
	dsn: dns,
	release: base.APP_SENTRY_RELEASE,
	integrations: [
		new Integrations.Vue({Vue, attachProps: true}),
		new Integrations.ExtraErrorData() // fetch extra data to errors
	],
});

console.log(base.APP_SENTRY_RELEASE);

Axios.interceptors.request.use(
	requestConfig => requestConfig,
	(requestError) => {
		Sentry.captureException(requestError);

		return Promise.reject(requestError);
	},
);

// Add a response interceptor
Axios.interceptors.response.use(
	response => response,
	(error) => {
		if (error.response.status >= 500) {
			Sentry.captureException(error);
		}

		return Promise.reject(error);
	},
);

