<template>
	<b-nav-item-dropdown :text="selectedLanguage.short|uppercase" v-model="selectedLanguage" aria-role="list" right>
		<b-dropdown-item v-for="lang in languages"
										 :key="lang.short"
										 :value="lang.short"
										 @click="selectedLanguage = lang">{{lang.long}}</b-dropdown-item>
	</b-nav-item-dropdown>
</template>

<script>
	import {mapState} from 'vuex';
	import Vue from 'vue';

	export default {
		name: "LangSwitcher",
		computed: mapState(['languages']),
		created() {
			// console.log('created', this.$store.state.curLanguage);
			Vue.i18n.set(this.$store.state.curLanguage.short);
		},
		data() {
			return {
				selectedLanguage: this.$store.state.curLanguage,
			}
		},
		filters: {
			uppercase: function(v) {
				return v.toUpperCase();
			}
		},
		watch: {
			'selectedLanguage': function(newLang) {
				Vue.i18n.set(newLang.short);
				this.$store.commit('setLanguage', newLang.short);
			}
		},
	}
</script>
