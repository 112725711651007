import IssueApiService from '__services/issues/IssueApiService';

export default {
	/**
	 * Gets all issue directories.
	 *
	 * @param state
	 * @returns {*|Getter<S, S>|Getter<S, R>}
	 */
	getDirectories: function(state) {
		return state.issueDirectories;
	},

	getCurrentDirectory: function(state) {
		return state.currentDirectory;
	},
	getDirectoryAuthorizedUsers: (state) => id => {
		const userPack = state.directoryAuthorizedUsers.find(item => item.id === id);
		if(!userPack) {
			return []
		}
		return userPack.users;
	},

	getCurrentIssue: function(state) {
		return state.currentIssue;
	},

	isThisCurrentDirectory: function(state) {
		return function(directoryId) {
			return (
				!!state.currentDirectory && state.currentDirectory.id == directoryId
			);
		};
	},
	isThisCurrentIssue: function(state) {
		return function(issueId) {
			return !!state.currentIssue && state.currentIssue.id == issueId;
		};
	},

	/**
	 * Finds category by ID.
	 *
	 * @param state
	 * @param getters
	 * @returns {*|null}
	 */
	getDirectory: (state, getters) => id => {
		return state.directoryCache.find(item => item.id === id);
	},

	/**
	 * Gets directory data from /edit/ API endpoint (more info).
	 */
	getDirectoryEdit: state => id => {
		return IssueApiService.getDirectoryEdit(id);
	},
	/**
	 * Gets possible issue statuses.
	 *
	 * @returns {*[]}
	 */
	getStatuses: state => state.statuses
};
