import Axios from 'axios';
import {base} from 'config/base';
import {urls} from 'config/urls';
import AxiosConfig from 'config/axios.js';

/**
 * Service for handling Groups module API requests.
 */
export default {
	/**
	 * Gets groups list from API.
	 *
	 * @returns {AxiosPromise<any>}
	 */
	getGroups:  function ()  {
		return Axios.get(
			//TODO Why you not use django reverse js ?
			base.VUE_APP_BACKEND_BASE_URL + urls.GROUPS,
			AxiosConfig.getDefault());
	},

	/**
	 * Gets single group by given ID from API.
	 *
	 * @param groupId
	 * @returns {AxiosPromise<any>}
	 */
	getGroup: function(groupId) {
		return Axios.get(
			base.VUE_APP_BACKEND_BASE_URL + urls.GROUPS  + groupId + '/',
			AxiosConfig.getDefault());
	},

	/**
	 * Updates group data to API.
	 *
	 * @param data
	 * @returns {AxiosPromise<any>}
	 */
	updateGroup: function (data) {
		return Axios.patch(
			base.VUE_APP_BACKEND_BASE_URL + urls.GROUPS + data.id + '/',
			data,
			AxiosConfig.getDefault());
	},

	/**
	 * Creates new group in API.
	 *
	 * @param data
	 * @returns {AxiosPromise<any>}
	 */
	createGroup: function (data) {
		return Axios.post(
			base.VUE_APP_BACKEND_BASE_URL + urls.GROUPS,
			data,
			AxiosConfig.getDefault());
	},

	/**
	 * Deletes group in API.
	 *
	 * @param data
	 * @returns {AxiosPromise<any>}
	 */
	deleteGroup: function (id) {
		return Axios.delete(
			base.VUE_APP_BACKEND_BASE_URL + urls.GROUPS + id + '/',
			AxiosConfig.getDefault());
	},

	/**
	 * Searches groups by string.
	 *
	 * @param string
	 * @returns {AxiosPromise<any>}
	 */
	searchGroups: function(string) {
		let config = AxiosConfig.getDefault();
		config.params = {
			search: string
		};
		return Axios.get(
			base.VUE_APP_BACKEND_BASE_URL + urls.GROUPS_SEARCH,
			config);
	},

}
