//Notifications config

export default {
	/**
	 * Gets default notification config in JS object.
	 */
	getDefaultMessage: function () {
		//https://github.com/euvl/vue-notification
		let config = {
			// (optional)
			// Name of the notification holder
			group: 'app',

			// (optional)
			// Class that will be assigned to the notification
			type: 'warn',

			// (optional)
			// Title (will be wrapped in div.notification-title)
			title: 'This is title',

			// Content (will be wrapped in div.notification-content)
			text: '',

			// (optional)
			// Overrides default/provided duration
			duration: 5000,

			// (optional)
			// Overrides default/provided animation speed
			speed: 1000,

			// (optional)
			// Data object that can be used in your template
			data: {}
		};
		return config;

	}
};
