import * as mt from '__store/mutations-types.js';

/**
 * Vuex store mutations for Users module.
 */
export default {
	[mt.CLEAR_SEARCH]: (state) => {
		state.query = '';
		state.issues.count = 0;
		state.issues.results = [];
		state.directories.count = 0;
		state.directories.results = [];
		state.loadNext = null;
	},
	[mt.INIT_SEARCH]: (state,payload) => {
		state.query = payload.query;
		state.issues.count = payload.data.issues.count;
		state.issues.results = payload.data.issues.results;
		state.directories.count = payload.data.directories.count;
		state.directories.results = payload.data.directories.results;
		let loadNext = null
		if(payload.data.issues.next) {
			loadNext = payload.data.issues.next;
		} else if(payload.data.directories.next) {
			loadNext = payload.data.directories.next;
		}
		state.loadNext = loadNext;
	},
	[mt.NEXT_SEARCH]: (state,payload) => {
		state.issues.results = [...state.issues.results, ...payload.data.issues.results];
		state.directories.results = [...state.directories.results, ...payload.data.directories.results];
		let loadNext = null;
		if(payload.data.issues.next) {
			loadNext = payload.data.issues.next;
		} else if(payload.data.directories.next) {
			loadNext = payload.data.directories.next;
		}
		state.loadNext = loadNext;
	}
};
