import Axios from 'axios';
import {base} from 'config/base';
import {urls} from 'config/urls';
import AxiosConfig from 'config/axios.js';

export default {
	getMyInbox: function (onlyUnread, offset = 0, limit = 10) {
		let config = AxiosConfig.getDefault();
		const url = onlyUnread ? urls.USERS_ME_INBOX_ONLY_UNREAD : urls.USERS_ME_INBOX;
		config['params'] = {limit: limit, offset: offset}
		return Axios.get(base.VUE_APP_BACKEND_BASE_URL + url, config);
	},
	getMyInboxUnreadCount: function () {
		let config = AxiosConfig.getDefault();
		return Axios.get(base.BACKEND_BASE_URL + urls.USERS_ME_INBOX_UNREAD_COUNT, config);
	},
	getNotification: function (id) {
		let config = AxiosConfig.getDefault();
		return Axios.get(base.BACKEND_BASE_URL + urls.USERS_ME_NOTIFICATION + id + '/', config);
	},
	toggleNotification: function (id) {
		let config = AxiosConfig.getDefault();
		return Axios.put(base.BACKEND_BASE_URL + urls.USERS_ME_INBOX + id + '/', '', config);
	},
	readAllIssueNotifications: function (id) {
		let config = AxiosConfig.getDefault();
		return Axios.put(base.BACKEND_BASE_URL + urls.USERS_ME_INBOX + id + '/all', '', config);
	},
}
