import EventBus, {EVENT_TYPES} from './../event-bus/index.js';

export default {

	state: {
		dialogs: []
	},

	data() {
		return {
			dialogs: []
		};
	},

	methods: {
		closeDialogHandler(id) {
			this.closeDialog({id});
		},

		getMaxDialogIndex() {
			if (this.dialogs.length > 0) {
				return Math.max.apply(Math, this.dialogs.map(function (o) {
					return o.dialogOptions.options.zIndex
				}))
			} else {
				return 1
			}
		},

		focus(dialog) {
			//console.log('focus', JSON.stringify(dialog));
			// set dialog zindex
			dialog.dialogOptions.options.zIndex = this.getMaxDialogIndex() + 1;
		},

		pinDialogHandler(id) {
			//console.log('pin-dialog-handler', dialog);
		},

		moveDialogHandler(dialog) {
			//console.log('move-dialog-handler', dialog);
		},

		dragEnd(dialog) {
			//console.log('drag-end', dialog);
			// set dialog zindex
			dialog.dialogOptions.options.zIndex = this.getMaxDialogIndex() + 1;
			// save to local storage for full restore
			localStorage.setItem('dialogs', JSON.stringify(dialog));
		},

		dragStart(dialog) {
			// console.log('drag-start', dialog);
		},

		generateDialogID() {
			return new Date().getTime().toString();
		},

		createDialogOptions({dialogOptions = {}}) {
			return {
				id: dialogOptions.id || this.generateDialogID(),
				title: dialogOptions.title || 'Some Default Title',
				options: {
					left: dialogOptions.left || Math.floor(Math.random() * (window.innerWidth / 2) + 1),
					top: dialogOptions.top || Math.floor(Math.random() * (window.innerHeight / 2) + 1),
					width: dialogOptions.width,
					height: dialogOptions.height,
					zIndex: this.getMaxDialogIndex() + 1,
					pinned: dialogOptions.pinned || false,
					buttonPin: dialogOptions.buttonPin || true,
					buttonClose: dialogOptions.buttonClose || true
				}
			};
		},

		openDialog(payload) {
			this.dialogs.push({
				dialogOptions: this.createDialogOptions(payload),
				component: payload.component
			});
		},

		closeDialog({id}) {
			if (!id) return;
			this.dialogs.splice(
				this.dialogs.findIndex(({dialogOptions}) => dialogOptions.id === id),
				1
			);
		},

		showMessages(messages) {
			for (const [key, value] of Object.entries(messages)) {
				this.$notify({
					type: value.type,
					group: value.group || 'app',
					text: this.$t(value.description),
					duration: 6000
				});
			}
		},

		searchComponent(componentName, props) {
			for (let dialog of this.dialogs) {
				console.log('Dialog:');
				console.log(dialog);
			}
		}
	},

	mounted() {
		EventBus.$on(EVENT_TYPES.OPEN_DIALOG, payload => this.openDialog(payload));
		EventBus.$on(EVENT_TYPES.CLOSE_DIALOG, payload => this.closeDialog(payload));
		EventBus.$on(EVENT_TYPES.NOTIFY_MESSAGES, mesasges => this.showMessages(mesasges));
	},

	watch: {
		dialogs: function checked(newVal, oldVal) {
			this.$store.commit('dialogs', newVal);
			localStorage.setItem('dialogs', JSON.stringify(newVal));
		},
	},
}
