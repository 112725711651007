import Axios from 'axios';
import {base} from 'config/base';
import {urls} from 'config/urls';
import AxiosConfig from 'config/axios.js';

/**
 * Service for handling Users module API requests.
 */
export default {
    /**
     * Gets users list from API.
     *
     * @returns {AxiosPromise<any>}
     */
    getUsers: function () {
        return Axios.get(
            base.VUE_APP_BACKEND_BASE_URL + urls.USERS,
            AxiosConfig.getDefault()
        );
    },

    /**
     * Gets single user by given ID from API.
     *
     * @param userId
     * @returns {AxiosPromise<any>}
     */
    getUser: function (userId) {
        return Axios.get(
            base.VUE_APP_BACKEND_BASE_URL + urls.USERS + userId + '/',
            AxiosConfig.getDefault()
        );
    },

    /**
     * Updates user data to API.
     *
     * @param data
     * @returns {AxiosPromise<any>}
     */
    updateUser: function (data) {
        return Axios.patch(
            base.VUE_APP_BACKEND_BASE_URL + urls.USERS + data.id + '/',
            data,
            AxiosConfig.getDefault()
        );
    },

    /**
     * Sends new user to API.
     *
     * @param data
     * @returns {AxiosPromise<any>}
     */
    inviteUser: function (data) {
        return Axios.post(
            base.VUE_APP_BACKEND_BASE_URL + urls.USERS_INVITATIONS + '/invite/',
            data,
            AxiosConfig.getDefault()
        );
    },

    /**
     * Sends data about invited user to API.
     *
     * @param data
     * @returns {AxiosPromise<any>}
     */
    acceptUserInvitation: function (data) {
        return Axios.post(
            base.VUE_APP_BACKEND_BASE_URL +
            urls.USERS_INVITATIONS +
            '/accept/' +
            data.token +
            '/',
            data,
            AxiosConfig.getDefault()
        );
    },

    /**
     * Deletes user in API.
     *
     * @param data
     * @returns {AxiosPromise<any>}
     */
    deleteUser: function (id) {
        return Axios.delete(
            base.VUE_APP_BACKEND_BASE_URL + urls.USERS + '/' + id + '/',
            AxiosConfig.getDefault()
        );
    },

    /**
     * Searches users by string.
     *
     * @param string
     * @returns {AxiosPromise<any>}
     */
    searchUsers: function (string) {
        let config = AxiosConfig.getDefault();
        config.params = {
            search: string
        };
        return Axios.get(base.VUE_APP_BACKEND_BASE_URL + urls.USERS_SEARCH, config);
    },

    getMyInbox: function (onlyUnread, offset = 0, limit = 10) {
        let config = AxiosConfig.getDefault();
        const url = onlyUnread ? urls.USERS_ME_INBOX_ONLY_UNREAD : urls.USERS_ME_INBOX;
		config['params'] = {limit: limit, offset: offset}
        return Axios.get(base.VUE_APP_BACKEND_BASE_URL + url, config);
    },
    getMyInboxUnreadCount: function () {
        let config = AxiosConfig.getDefault();
        return Axios.get(base.VUE_APP_BACKEND_BASE_URL + urls.USERS_ME_INBOX_UNREAD_COUNT, config);
    },
    getNotification: function (id) {
        let config = AxiosConfig.getDefault();
        return Axios.get(base.VUE_APP_BACKEND_BASE_URL + urls.USERS_ME_NOTIFICATION + id + '/', config);
    },
    toggleNotification: function (id) {
        let config = AxiosConfig.getDefault();
        return Axios.put(base.VUE_APP_BACKEND_BASE_URL + urls.USERS_ME_INBOX + id + '/', '', config);
    },
    readAllIssueNotifications: function (id) {
        let config = AxiosConfig.getDefault();
        return Axios.put(base.VUE_APP_BACKEND_BASE_URL + urls.USERS_ME_INBOX + id + '/all', '', config);
    },
    myTasks: function () {
        let config = AxiosConfig.getDefault();
        return Axios.get(base.VUE_APP_BACKEND_BASE_URL + urls.USERS_ME_MY_TASKS, config);
    },
    myWork: function () {
        let config = AxiosConfig.getDefault();
        return Axios.get(base.VUE_APP_BACKEND_BASE_URL + urls.USERS_ME_MY_WORK, config);
    },
    userWork: function (id) {
        let config = AxiosConfig.getDefault();
        return Axios.get(base.VUE_APP_BACKEND_BASE_URL + 'users/' + id + '/issues/', config);
    },
    resetPassword: function (email) {
        let config = AxiosConfig.getDefault();
        return Axios.post(base.VUE_APP_BACKEND_BASE_URL + urls.USERS_RESET_PASSWORD, {email: email}, config);
    },
    newPassword: function (token, password) {
        let config = AxiosConfig.getDefault();
        return Axios.post(base.VUE_APP_BACKEND_BASE_URL + urls.USERS_NEW_PASSWORD, {
            change_password_token: token,
            new_password: password
        }, config);
    }
};
