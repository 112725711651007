/**
 * Vuex store getters for Users module.
 */
export default {
    /**
     * Gets all users.
     *
     * @param state
     * @returns {*|Getter<S, S>|Getter<S, R>}
     */
    getUsers: function (state) {
        return state.users;
    },

    /**
     * Filters users array to find user object by id.
     * @param state
     */
    getUser: state => userId => {
        return state.users.find(function (user) {
            return user.id === userId;
        });
    },

    inbox: state => {
        return state.inbox;
    },
    inboxUnreadCount: state => {
        return state.inboxUnreadCount;
    },
    myTasks: state => {
        return state.myTasks;
    },
    isLoadingInbox: state => state.loadingInbox,
    myWork: state => {
        return state.myWork;
    },
    inboxOnlyUnread: (state) => state.inboxOnlyUnread,
	inboxAvailableNextPage: (state) => state.inboxAvailableNextPage,
    getPermissions: () => {
        return [
            {
                text: 'External', //TODO - put into translation keys
                value: 'external'
            },
            {
                text: 'Regular',
                value: 'regular'
            },
            {
                text: 'Administrator',
                value: 'admin'
            }
        ];
    }
};
