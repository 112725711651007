<template>
	<v-card flat style="background-color: rgba(0,0,0,0);">
		<CreateDirectory :directory="contextMenuDirectory" v-if="leastRegular" @sending="onSending">
			<template v-slot="{ open }">
				<v-tooltip right>
					<template v-slot:activator="{ on }">
						<v-btn icon rounded @click="open" v-on="on">
							<v-icon>mdi-plus</v-icon>
						</v-btn>
					</template>
					<span>Create root directory</span>
				</v-tooltip>
			</template>
		</CreateDirectory>
		<directory-tree
			class="py-3"
			v-if="tree"
			:items="tree"
			hoverable
			dense
			:expanded.sync="expanded"
			:active.sync="active"
			:is-draggable="leastRegular"
			@node-right-click="openContext"
		>
		</directory-tree>
		<div class="h-100">

		</div>

		<vue-context ref="contextMenu">
			<li>
				<CreateDirectory :directory="contextMenuDirectory" @created="createdDirectory" @sending="onSending"></CreateDirectory>
			</li>
			<li>
				<a @click="deleteDialog" class="black--text">
					<v-icon :size="20" color="grey darken-1" class="mr-1 mt-n1">mdi-folder-remove</v-icon>
					Delete directory
				</a>
			</li>
		</vue-context>
        <v-dialog v-model="showDeleteDialog" width="450px">
            <v-card>
                <v-card-title>
                    Do you really want to delete the folder?
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                        color=""
                        text
                        @click="showDeleteDialog = false"
                    >
                        No
                    </v-btn>

                    <v-btn
                        color="green darken-1"
                        text
                        @click="deleteDirectory"
                    >
                        Yes
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
	</v-card>
</template>

<script>

    import {VueContext} from 'vue-context';
    import CreateDirectory from '__components/features/issues/CreateDirectory/App';
    import IssueDirectoriesItem from '__components/features/issues/IssueDirectoriesItem/App';
    import DirectoryTree from "../DirectoryTree/App";
    import UserRoleMixin from "__components/mixins/UserRoleMixin";

    export default {
        name: 'IssueDirectories',
		mixins: [
            UserRoleMixin
		],
        components: {
            DirectoryTree,
            VueContext,
            CreateDirectory,
            IssueDirectoriesItem
        },
        props: {
            showIssues: {
                type: Boolean,
                default: false
            }
        },
        data: function () {
            return {
                search: null,
                expanded: [],
                active: [],
                getMonitor: true,
                over: null,
				loading: false,
                contextMenuDirectory: null,
                deleteFolder: null
            };
        },
        computed: {
            tree() {
                return this.$store.getters['issues/getDirectories'];
            },
            treeState() {
                return this.buildTree(this.tree);
            },
            openedDirectoryId() {
                return this.$route.params.id;
            },
            showDeleteDialog: {
                get() {
                    return !!this.deleteFolder;
                },
                set(value) {
                    if(!value) {
                        this.deleteFolder = null;
                    }
                }
            }
        },
        watch: {
            treeState: function (newVal, oldVal) {
                if(!this.compareSets(newVal.open, oldVal.open) || !this.compareSets(newVal.active, oldVal.active)) {
                    this.expanded = [...new Set([...this.expanded, ...newVal.open])];
                    this.active = newVal.active;
                }
            }
        },
        created() {
            const vm = this;
           	this.loadTree();
            this.$event.$on(this.$event.types.LOGIN, function () {
                vm.loadTree();
            });
            this.$event.$on(this.$event.types.CREATED_DIRECTORY, function () {
                vm.loadTree();
            });
            this.$event.$on(this.$event.types.DELETED_DIRECTORY, function () {
                vm.loadTree();
            });
            this.$event.$on(this.$event.types.RELOAD_DIRECTORY_TREE, function () {
                vm.loadTree();
            });
        },
        methods: {
            onSending(status) {
                this.$emit('loading', status);
			},
            //Opens context menu
            openContext(payload) {
                if(this.leastRegular) {
                    this.contextMenuOpen(payload.event, payload.item);
                }
            },
            contextMenuOpen(event, directory) {
                this.contextMenuDirectory = directory;
                this.$refs.contextMenu.open(event, directory);
            },
			compareSets(aArray, bArray) {
                const as = new Set(aArray);
                const bs = new Set(bArray);
                if (as.size !== bs.size) return false;
                for (var a of as) if (!bs.has(a)) return false;
                return true;
			},
			loadTree() {
                if(!this.isLogged) {
                    return;
				}
                const vm = this;
                this.$emit('loading', true);
				//this.loading = true;
                this.$store.dispatch('issues/loadDirectories')
					.finally(() => {
                        vm.$emit('loading', false);
						//vm.loading = false;
					})
			},
            deleteDialog() {
                const _this = this;
                this.deleteFolder = this.contextMenuDirectory;
            },
            deleteDirectory() {
                const _this = this;
                this.$store
                    .dispatch('issues/deleteDirectory', {
                        directory: this.deleteFolder
                    })
                    .then(function (request) {
                        _this.$event.$emit(_this.$event.types.NOTIFY_MESSAGES, [
                            {
                                type: 'success',
                                title: 'Success',
                                text: 'Directory was deleted.'
                            }
                        ]);
                    })
                    .catch(function (error) {
                        console.log(error);
                        _this.$event.$emit(_this.$event.types.NOTIFY_MESSAGES, [
                            {
                                type: 'error',
                                title: 'Error',
                                text: 'Something went wrong.'
                            }
                        ]);
                    })
                    .finally(function () {
                        _this.deleteFolder = null;
                    });

            },
            createdDirectory(payload) {
                if(payload.parent) {
                    this.expanded = [... new Set([...this.expanded, payload.parent])];
				}
			},
            showDetail(id) {
                this.$router.push({name: 'issueDirectory', params: {id: id}});
            },
            buildTree(directories) {
                const vm = this;
                return directories.reduce(
                    function (accumulator, item) {
                        let subDirs = vm.buildTree(item.child_directories);
                        let active = item.id === vm.openedDirectoryId;
                        let open = subDirs.open.length > 0 || subDirs.active.length > 0;
                        if (active) {
                            accumulator.active.push(item.id);
                        }
                        if (open) {
                            accumulator.open.push(item.id);
                        }
                        accumulator.open = [...accumulator.open, ...subDirs.open];
                        accumulator.active = [...accumulator.active, ...subDirs.active];
                        return accumulator;
                    },
                    {active: [], open: []}
                );
            }
        }
    };
</script>
