import Vue from './../config/vue/vue.js';
import VueRouter from 'vue-router';
import {routes} from './routes.js';

Vue.use(VueRouter);

const router = new VueRouter({
	mode: 'history',
	base: '/',
	routes
});

router.beforeEach((to, from, next) => {
	if (to.matched.some(record => record.meta.requiresAuth)) {
		if (localStorage.getItem('authToken') === null) {
			next({
				path: 'login',
				//params: {nextUrl: to.fullPath}
			})
		}
	}
	next();
});

export default router;
