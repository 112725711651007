import actions from './actions';
import getters from './getters';
import mutations from './mutations';

/**
 * Vuex store for Users module.
 * For more see: https://vuex.vuejs.org/guide/modules.html.
 */
const Users = {
	namespaced: true,
	state: {
		users: [],
		groups: [],
		userLoaders: [],
		loadingInbox: false,
		inbox: [],
		inboxCurrentOffset: 0,
		inboxPerPage: 10,
		inboxAvailableNextPage: false,
		inboxUnreadCount: 0,
		myTasks: [],
		myWork:[],
		inboxOnlyUnread: true,
	},
	mutations: mutations,
	actions: actions,
	getters: getters
};

export default Users;
