import {base} from "../../config/base";
import {urls} from "../../config/urls";
import Axios from "axios";
import AxiosConfig from 'config/axios.js';
import EventBus from '__event-bus/EventBus';
import URL from 'url';

export default {
	/**
	 *
	 * @param {string} username
	 * @param {string} password
	 * @returns {Promise<AxiosResponse<any>>}
	 */
	login(username, password) {
		let url = base.VUE_APP_BACKEND_BASE_URL + urls.USERS_LOGIN_LOCAL;

		let postData = {
			username: username,
			password: password
		};

		let axiosConfig = {
			headers: {
				'Content-Type': 'application/json',
			},
		};

		return Axios.post(url, postData, axiosConfig)
			.then(response => this.loginSuccessful(response))
			.catch(error => this.loginFailed(error))
	},
	/**
	 *
	 * @param {string} authToken
	 * @returns {Promise<AxiosResponse<any>>}
	 */
	googleLogin(authToken) {
		const url = base.VUE_APP_BACKEND_BASE_URL + urls.USERS_LOGIN_GOOGLE;
		const axiosConfig = {
			headers: {
				'Content-Type': 'application/json',
			},
		};
		return Axios.post(url, {code: authToken}, axiosConfig)
			.then(response => this.loginSuccessful(response, true))
			.catch(error => this.loginFailed(error, true));
	},
	logout() {
		const url = base.VUE_APP_BACKEND_BASE_URL + urls.USERS_LOGOUT;
		const config = AxiosConfig.getDefault();
		return Axios.post(url, {}, config);
	},
	/**
	 *
	 * @param response
	 * @param {boolean} googleLogin
	 * @returns {*}
	 */
	loginSuccessful(response, googleLogin = false) {
		if (!response.data.auth_token) {
			return this.loginFailed(response, googleLogin);
		}

		const user = response.data;
		user.googleLogin = googleLogin;

		localStorage.setItem('authToken', response.data.auth_token);
		const d = new Date();
		d.setTime(d.getTime() + (14*24*60*60*1000));
		const baseParsed = URL.parse(base.VUE_APP_BACKEND_BASE_URL);
		document.cookie = "sessionid=" + response.data.session_key + ";expires=" + d.toUTCString() + ";path=/;domain=." + baseParsed.host + ";";
		return new Promise((resolve) => resolve({user: user, token: response.data.auth_token}));
	},
	/**
	 *
	 * @param error
	 * @param {boolean} googleLogin
	 * @returns {*}
	 */
	loginFailed(error, googleLogin = false) {
		console.log(error);
		const response = error.response;
		const errorMessages = []
		if (googleLogin && response.status === 404) {
			errorMessages.push({
				type: 'error',
				title: 'Error',
				text: 'Google user is not registered in Motion Task'
			});
		} else if (response.status === 400 && response.data.error && response.data.error.non_field_errors) {
			response.data.error.non_field_errors.map(item => {
				return {
					type: 'error',
					title: 'Error ' + item.code,
					text: item.message
				};
			}).forEach(item => errorMessages.push(item));
		} else {
			errorMessages.push({
				type: 'error',
				title: 'Error',
				text: 'Something get wrong'
			})
		}
		if (errorMessages.length > 0) {
			EventBus.$emit(EventBus.types.NOTIFY_MESSAGES, errorMessages);
		}

		return new Promise((resolve, reject) => reject(error));
	},
	getMe() {
		const url = base.VUE_APP_BACKEND_BASE_URL + urls.USERS_ME;
		const config = AxiosConfig.getDefault();
		return Axios.get(url, config);
	},
}
