//Actions for Users
import {
    ADD_USER,
    ADD_USER_LOADER,
    SET_USERS,
    SET_INBOX,
	RESET_INBOX,
	APPEND_INBOX,
	PREPEND_INBOX,
    SET_MY_TASKS,
    SET_MY_WORK,
    SET_LOADING_INBOX,
    SET_INBOX_UNREAD_COUNT,
	SET_INBOX_ONLY_UNREAD,
    READ_ALL_INBOX,
    TOGGLE_NOTIFICATION
} from '__store/mutations-types';
import UserApiService from '__services/users/UserApiService';

import EventBus from '__event-bus/EventBus';

/**
 * Vuex store actions for Users module.
 */
export default {
    /**
     * Reloads users in state.
     *
     * @param context
     */
    reloadUsers(context) {
        return UserApiService.getUsers()
            .then(function (request) {
                context.commit(SET_USERS, request.data);
            })
            .catch(request => console.log(request));
    },

    /**
     * Gets user by ID from API.
     * Returns Promise.
     */
    userById: (context, payload) => {
        const id = payload.id;
        let loader = context.state.userLoaders.find(item => item.id === id);
        if (loader) {
            return loader.promise;
        }
        let user = context.getters.getUser(id);
        let promise = null;
        if (!user) {
            promise = UserApiService.getUser(id);
            loader = {id: id, promise: promise};
            context.commit(ADD_USER_LOADER, loader);
            promise.then(function (response) {
                context.commit(ADD_USER, response.data);
            });
        } else {
            promise = new Promise((resolve, reject) => resolve({data: user}));
        }
        return promise;
    },

    inbox(context, payload) {
        context.commit(SET_LOADING_INBOX, true);
		context.commit(RESET_INBOX);
        return UserApiService.getMyInbox(context.state.inboxOnlyUnread, context.state.inboxCurrentOffset, context.state.inboxPerPage)
            .then(response => {
                context.commit(SET_INBOX, response.data);
            })
            .catch(error => {
                console.log(error);
                EventBus.$emit(EventBus.types.NOTIFY_MESSAGES, [
                    {
                        type: 'error',
                        title: 'Error',
                        text: 'Could not fetch inbox.'
                    }
                ]);
            }).finally(() => context.commit(SET_LOADING_INBOX, false));
    },
	inboxNextPage(context, payload) {
		context.commit(SET_LOADING_INBOX, true);
		return UserApiService.getMyInbox(context.state.inboxOnlyUnread, context.state.inboxCurrentOffset, context.state.inboxPerPage)
			.then(response => {
				context.commit(APPEND_INBOX, response.data);
			})
			.catch(error => {
				console.log(error);
				EventBus.$emit(EventBus.types.NOTIFY_MESSAGES, [
					{
						type: 'error',
						title: 'Error',
						text: 'Could not fetch inbox.'
					}
				]);
			}).finally(() => context.commit(SET_LOADING_INBOX, false));
	},
    async getNotification(context, {id}) {
        const response = await UserApiService.getNotification(id);
        return response.data;
    },
    inboxUnreadCount(context, payload) {
        context.commit(SET_LOADING_INBOX, true);
        return UserApiService.getMyInboxUnreadCount()
            .then(response => {
				const currentCount = context.state.inboxUnreadCount;
                context.commit(SET_INBOX_UNREAD_COUNT, response.data);
				const diff = context.state.inboxUnreadCount - currentCount;
				if(diff > 0) {
					return UserApiService.getMyInbox(context.state.inboxOnlyUnread, 0, diff)
						.then(response => {
							context.commit(PREPEND_INBOX, response.data);
						})
						.catch(error => {
							console.log(error);
							EventBus.$emit(EventBus.types.NOTIFY_MESSAGES, [
								{
									type: 'error',
									title: 'Error',
									text: 'Could not fetch inbox.'
								}
							]);
						})
				}
            })
            .catch(error => {
                console.log(error);
                EventBus.$emit(EventBus.types.NOTIFY_MESSAGES, [
                    {
                        type: 'error',
                        title: 'Error',
                        text: 'Could not fetch inbox unread count.'
                    }
                ]);
            }).finally(() => context.commit(SET_LOADING_INBOX, false));
    },

    toggleNotification(context, payload) {
        return UserApiService.toggleNotification(payload.id).then(() => {
            context.commit(TOGGLE_NOTIFICATION, payload);
        });
    },

    readAllIssueNotifications(context, payload) {
        return UserApiService.readAllIssueNotifications(payload.id).then(() => {
            context.commit(TOGGLE_NOTIFICATION, payload);
        });
    },

    readAllUnreadNotifications(context) {
        const inbox = context.getters.inbox;
        const unread = inbox.filter(item => item.status !== 'read');
        return Promise.all(unread.map(item => UserApiService.toggleNotification(item.id)))
            .then(() => {
                context.commit(READ_ALL_INBOX);
            });
    },

    myTasks(context, payload) {
        return UserApiService.myTasks()
            .then(response => {
                context.commit(SET_MY_TASKS, response.data);
                return response.data;
            })
            .catch(error => {
                console.log(error);
                EventBus.$emit(EventBus.types.NOTIFY_MESSAGES, [
                    {
                        type: 'error',
                        title: 'Error',
                        text: 'Could not fetch my tasks.'
                    }
                ]);
                return [];
            });
    },

    myWork(context, payload) {
        return UserApiService.myWork()
            .then(response => {
                context.commit(SET_MY_WORK, response.data);
                return response.data;
            })
            .catch(error => {
                console.log(error);
                EventBus.$emit(EventBus.types.NOTIFY_MESSAGES, [
                    {
                        type: 'error',
                        title: 'Error',
                        text: 'Could not fetch my tasks.'
                    }
                ]);
                return [];
            });
    },
    userWork(context, payload) {
        return UserApiService.userWork(payload.id)
            .then(response => {
                context.commit(SET_MY_WORK, response.data);
                return response.data;
            })
            .catch(error => {
                console.log(error);
                EventBus.$emit(EventBus.types.NOTIFY_MESSAGES, [
                    {
                        type: 'error',
                        title: 'Error',
                        text: 'Could not fetch my tasks.'
                    }
                ]);
                return [];
            });
    },
    initOnlyUnread(context) {
        const onlyUnread = localStorage.getItem('inboxOnlyUnread');
        context.commit(SET_INBOX_ONLY_UNREAD, (onlyUnread !== null && onlyUnread !== undefined) ? onlyUnread : true);
    },
    setOnlyUnread(context, onlyUnread) {
        localStorage.setItem('inboxOnlyUnread', onlyUnread);
        context.commit(SET_INBOX_ONLY_UNREAD, onlyUnread);
    },
    /**
     *
     * @param context
     * @param payload
     * @returns {*|Getter<S, S>|Getter<S, R>|AxiosPromise<any>}
     */
    searchUsers(context, payload) {
        return UserApiService.searchUsers(payload.string);
    }
};
