<template>
	<search-result-item :to="to">
		<template v-slot:avatar>
			<issue-avatar :issue="issue"></issue-avatar>
		</template>
		<template v-slot:name>{{ name }}</template>
		<template v-slot:subline>
			<span>{{ status }}</span>
			<v-chip outlined v-if="directory">{{ folderName }}</v-chip>
		</template>
	</search-result-item>
</template>

<script>
import SearchResultItem from './SearchResultItem';
import IssueAvatar from "../../features/issues/IssueAvatar/App";

export default {
	name: 'SearchResultItemIssue',
	components: {
        IssueAvatar,
		SearchResultItem
	},
	props: ['issue'],
	computed: {
		name: function() {
			return this.issue.name;
		},
		to: function(){
		    if(!this.directory) {
		        return undefined;
			}
			return { name: 'issueDirectoryIssue',params: {id: this.directory.id, issueId: this.issue.id}};
		},
		firstNameChar: function() {
			return this.issue.name[0].toUpperCase();
		},
		status: function() {
			return this.firstUpperCase(this.issue.status);
        },
        hasAttachments: function() {
            // DOTO
        },
        directory: function () {
            return this.issue.issue_directory;
        },
        folderName: function() {
            return  this.directory.name;
        }
    },
	methods: {
		firstUpperCase(string) {
			return string.charAt(0).toUpperCase() + string.slice(1);
		}
	}
};
</script>

<style>
</style>
