import Axios from 'axios';
import {base} from 'config/base';
import {urls} from 'config/urls';
import AxiosConfig from 'config/axios.js';

/**
 * Service for handling Issues module API requests.
 */
export default {
	/**
	 * Gets issue dirs from API.
	 *
	 * @returns {AxiosPromise<any>}
	 */
	getDirectories: function () {
		return Axios.get(
			base.VUE_APP_BACKEND_BASE_URL + urls.ISSUES_DIRECTORY,
			AxiosConfig.getDefault()
		);
	},

	/**
	 * Gets  directory by ID.
	 *
	 * @returns {AxiosPromise<any>}
	 */
	getDirectory: function (id) {
		return Axios.get(
			base.VUE_APP_BACKEND_BASE_URL + urls.ISSUES_DIRECTORY + id + '/',
			AxiosConfig.getDefault()
		);
	},

	/**
	 * Gets  directory by ID for editing => with more data.
	 *
	 * @returns {AxiosPromise<any>}
	 */
	getDirectoryEdit: function (id) {
		return Axios.get(
			base.VUE_APP_BACKEND_BASE_URL + urls.ISSUES_DIRECTORY + id + '/edit/',
			AxiosConfig.getDefault()
		);
	},
	getAuthorizedDirectoryUsers: function (id) {
		return Axios.get(
			base.VUE_APP_BACKEND_BASE_URL + urls.ISSUES_DIRECTORY + id + '/authorized-users/',
			AxiosConfig.getDefault()
		);
	},
	/**
	 * Gets issues list from API.
	 *
	 * @returns {AxiosPromise<any>}
	 */
	getIssueCategories: function () {
		return Axios.get(
			base.VUE_APP_BACKEND_BASE_URL + urls.ISSUES_DIRECTORY,
			AxiosConfig.getDefault()
		);
	},

	/**
	 * Gets issue.
	 *
	 * @param id
	 * @returns {AxiosPromise<any>}
	 */
	getIssue: function (id) {
		return Axios.get(
			base.VUE_APP_BACKEND_BASE_URL + urls.ISSUES + id + '/',
			AxiosConfig.getDefault()
		);
	},

	/**
	 * Creates new issue in API.
	 *
	 * @param data
	 * @returns {AxiosPromise<any>}
	 */
	createIssue: function (data) {
		return Axios.post(
			base.VUE_APP_BACKEND_BASE_URL + urls.ISSUES,
			data,
			AxiosConfig.getDefault()
		);
	},

	/**
	 * Updates existing issue in API.
	 *
	 * @param data
	 * @returns {AxiosPromise<any>}
	 */
	updateIssue: function (data) {
		const version = data.version;
		const force = data.hasOwnProperty('force') && data.force === true;
		if (force) {
			delete data.force;
		}
		delete data.version;
		const requestData = {
			version: version,
			data: data
		};
		if(force)  {
			requestData.force_update = true;
		}
		return Axios.patch(
			base.VUE_APP_BACKEND_BASE_URL + urls.ISSUES + data.id + '/',
			requestData,
			AxiosConfig.getDefault()
		);
	},

	/**
	 * Updates existing directory in API.
	 *
	 * @param data
	 * @returns {AxiosPromise<any>}
	 */
	updateDirectory: function (data) {
		return Axios.patch(
			base.VUE_APP_BACKEND_BASE_URL + urls.ISSUES_DIRECTORY + data.id + '/edit/',
			data,
			AxiosConfig.getDefault()
		);
	},

	createDirectory: function (data) {
		return Axios.post(
			base.VUE_APP_BACKEND_BASE_URL + urls.ISSUES_DIRECTORY,
			data,
			AxiosConfig.getDefault()
		);
	},

	deleteDirectory: function (id) {
		return Axios.delete(
			base.VUE_APP_BACKEND_BASE_URL + urls.ISSUES_DIRECTORY + id + '/edit/',
			AxiosConfig.getDefault()
		);
	},

	deleteIssue: function (id) {
		return Axios.delete(
			base.VUE_APP_BACKEND_BASE_URL + urls.ISSUES + id + '/',
			AxiosConfig.getDefault()
		);
	},

	uploadFile: function (file) {
		const data = new FormData();
		data.append('file', file);
		return Axios.post(
			base.VUE_APP_BACKEND_BASE_URL + urls.ISSUES_FILES,
			data,
			AxiosConfig.getDefault()
		);
	},
	commentCurrentIssue: function (issueId, comment) {
		return Axios.post(
			base.VUE_APP_BACKEND_BASE_URL + urls.ISSUES + issueId + '/comment/',
			comment,
			AxiosConfig.getDefault()
		);
	},
	updateComment(issueId, comment) {
		return Axios.patch(
			base.VUE_APP_BACKEND_BASE_URL + urls.ISSUES + issueId + '/comment/' + comment.id + '/',
			comment,
			AxiosConfig.getDefault()
		);
	}
};
