<template>
	<div
		class="v-treeview-node "
		:class="classes"
	>
		<drop
			class="drop"
			@dragover="handleDragover(...arguments)"
			@drop="handleDrop"
			@dragleave="onDragleave"
		>
			<div
				@mousedown.right="rightClick($event)"
				@contextmenu.prevent
			>
				<drag
					:draggable="isDraggable"
					:transfer-data="{ group: 'directory', id: item.id, parent: item.parent}"
					@dragstart="dragging = 'directory'"
					@dragend="dragging = null"
					class="v-treeview-node__root drag"
					:class="[ isActive ? 'v-treeview-node--active primary--text': '']"
				>

					<template slot="image">
						<v-chip
							class="ma-2"
						>
							<v-avatar left>
								<v-icon>mdi-folder</v-icon>
							</v-avatar>
							{{ item.name }}
						</v-chip>
					</template>
					<v-icon
						v-if="!isEmpty"
						:class="{'v-treeview-node__toggle--open' : isOpen}"
						@click="toggle"
						class="v-treeview-node__toggle ml-1 "
						link
						role="button">
						mdi-chevron-down
					</v-icon>
					<div class="v-treeview-node__content ml-2">
						<issue-directories-item
							:directory="item"
						></issue-directories-item>
						<v-icon :size="15" v-if="isOver">
							mdi-plus
						</v-icon>
					</div>
				</drag>
			</div>
		</drop>

		<div v-if="displayed.length > 0" class="v-treeview-node__children">
			<directory-tree-node
				v-for="item in displayed"
				:key="item.id"
				:item="item"
				@toggle="onToggle"
				:expanded.sync="expanded"
				:active.sync="active"
				:is-draggable="isDraggable"
				:parents="[...parents, rootId]"
				@node-right-click="rightUpEmit"
			></directory-tree-node>
		</div>

	</div>
</template>

<script>
    import Draggable from 'vuedraggable';
    import IssueDirectoriesItem from '__components/features/issues/IssueDirectoriesItem/App';
    import SortingMixin from "./SortingMixin";

    export default {
        name: "DirectoryTreeNode",
        components: {
            IssueDirectoriesItem
        },
        data() {
            return {
                styleElem: null,
                isOver: false
            };
        },
        props: {
            item: {},
            expanded: {
                type: Array,
                default: () => []
            },
            active: {
                type: Array,
                default: () => []
            },
            parents: {
                type: Array,
                default: () => []
            },
            isDraggable: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            isActive: function () {
                return this.active.indexOf(this.rootId) >= 0;
            },
            isOpen: function () {
                return this.expanded.indexOf(this.rootId) >= 0;
            },
            rootId: function () {
                return this.item.id;
            },
            items: {
                get: function () {
                    return this.item.child_directories.sort((a, b) => a.name.localeCompare(b.name));
                },
                set: function () {

                }
            },
            displayed: {
                get: function () {
                    if (!this.isOpen) {
                        return [];
                    }
                    return this.items;
                },
                set: function () {

                }
            },
            isEmpty: function () {
                return this.items.length === 0;
            },
            classes: function () {
                return {
                    'v-treeview-node--leaf': this.isEmpty
                };
            }
        },
        created() {
            const sheet = document.createElement('style')
            sheet.innerHTML = ".ghost-" + this.item.id + ".chosen-" + this.item.id + " { display:none;}";
            this.styleElem = sheet;
            document.body.appendChild(sheet);
        },
        methods: {
            toggle: function () {
                this.$emit('toggle', {
                    id: this.rootId,
                    open: !this.isOpen
                });
            },
            onToggle: function (payload) {
                this.$emit('toggle', payload);
            },
            rightClick: function (event) {
                this.$emit('node-right-click', {event: event, item: this.item});
            },
            rightUpEmit: function (payload) {
                this.$emit('node-right-click', payload);
            },
            handleDragover(data, event) {
                if (
                    'directory' === data.group
                    && this.rootId !== data.id
                    && this.rootId !== data.parent
                    && this.parents.indexOf(data.id) < 0
                ) {
                    this.isOver = true;
                } else if (
                    'issue' === data.group
                    && this.rootId !== data.issue.directory
				) {
                    this.isOver = true;
                } else {
                    event.dataTransfer.dropEffect = 'none';
                    this.isOver = false;

                }
            },
            handleDrop(data) {
                const vm = this;
                this.isOver = false;
                if(data.group === 'directory') {
                    const id = data.id;
                    const directories = this.items
                        .filter(item => item.id !== id);
                    directories.push({
                        id: id,
                        parent: this.rootId
                    });
                    this.$store.dispatch('issues/moveDirectory', {directories: directories})
                        .then(function () {
                            vm.$emit('toggle', {
                                id: vm.rootId,
                                open: true,
                            })
                            vm.$event.$emit(vm.$event.types.MOVED_DIRECTORY, id);
                        });
                } else {
                    this.$store.dispatch('issues/addDirectorySubIssue', {
                        directoryId: this.rootId,
                        subIssue: data.issue
                    })
				}
            },
            onDragleave() {
                this.isOver = false;
            }
        }

    }
</script>
