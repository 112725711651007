import * as mt from '../../mutations-types.js';

/**
 * Vuex store mutations for Users module.
 */
export default {
	[mt.SET_USERS]: (state, data) => {
		state.users = data;
	},
	[mt.ADD_USER]: (state, data) => {
		state.users.push(data);
		state.userLoaders = state.userLoaders.filter(item => item.id !== data.id);
	},
	[mt.ADD_USER_LOADER]: (state, data) => {
		state.userLoaders.push(data);
	},
	[mt.SET_INBOX]: (state, data) => {
		state.inbox = data.results;
		if(data.results.length > 0) {
			state.inboxCurrentOffset = state.inboxCurrentOffset + data.results.length;
		}
		state.inboxAvailableNextPage = data.results.length == state.inboxPerPage;
		console.log(state);
	},
	[mt.RESET_INBOX]: (state) => {
		state.inbox = [];
		state.inboxCurrentOffset = 0;
		state.inboxAvailableNextPage = false;
	},
	[mt.APPEND_INBOX]: (state, data) => {
		console.log(data.results);
		state.inbox = state.inbox.concat(data.results);
		if(data.results.length > 0) {
			state.inboxCurrentOffset = state.inboxCurrentOffset + data.results.length;
		}
		state.inboxAvailableNextPage = data.results.length == state.inboxPerPage;
		console.log(state);
	},
	[mt.PREPEND_INBOX]: (state, data) => {
		state.inbox = data.results.concat(state.inbox);
		state.inboxCurrentOffset = state.inboxCurrentOffset + data.results.length;
	},
	[mt.SET_INBOX_UNREAD_COUNT]: (state, data) => {
		state.inboxUnreadCount = data.count;
	},
	[mt.TOGGLE_NOTIFICATION]: (state, data) => {
		state.inbox.filter(item => item.id === data.id).map(item => item.status = (item.status === "read" ? "unread" : "read"));
		state.inboxUnreadCount = state.inbox.filter(item => item.status !== "read").length;
	},
	[mt.READ_ALL_INBOX] : (state, data) => {
		state.inbox.map(item => item.status = "read");
		state.inboxUnreadCount = 0;
	},
	[mt.SET_MY_TASKS]: (state, data) => {
		state.myTasks = data;
	},
	[mt.SET_MY_WORK]: (state, data) => {
		state.myWork = data;
	},
	[mt.SET_LOADING_INBOX]: (state, data) => {
		state.loadingInbox = data;
	},
	[mt.SET_INBOX_ONLY_UNREAD]: (state, data) => {
		state.inboxOnlyUnread = data;
	}
};
