<template>
	<v-app-bar app dark clipped-left fixed class="header elevation-1" color="almostBlack">
		<v-toolbar-title class="header__title">
			<app-logo></app-logo>
		</v-toolbar-title>

		<v-btn
			:to="{name: 'inbox'}"
			text
			large
			class="text-capitalize font-weight-light subtitle-1 mx-1"
			@click="inboxReload"
		>
			<v-badge class="inbox-badge" :value="unreadCount > 0">
				<template  v-slot:badge>
					<span v-if="!loadingInbox">{{ unreadCount }}</span>
					<v-progress-circular indeterminate v-if="loadingInbox" :size="15"></v-progress-circular>
				</template>
				Inbox
			</v-badge>
		</v-btn>
		<v-btn
			:to="{name: 'myTasks'}"
            text
			large
			class="text-capitalize font-weight-light subtitle-1 mx-1"
		>My Tasks
		</v-btn>
		<v-btn
			:to="{name: 'myWork'}"
			text
			large
			class="text-capitalize font-weight-light subtitle-1 mx-1"
		>My Work
		</v-btn>
		<div v-if="isAdmin">
			<v-btn
				:to="{name: 'usersList'}"
				text
				large
				class="text-capitalize font-weight-light subtitle-1 mx-1"
			>{{$t('Users')}}
			</v-btn>
			<v-btn
				:to="{name: 'groupsList'}"
				text
				large
				class="text-capitalize font-weight-light subtitle-1 mx-1"
			>{{$t('Groups')}}
			</v-btn>
		</div>
		<div class="header__right">
			<v-btn icon @click="showSearch">
				<v-icon>mdi-magnify</v-icon>
			</v-btn>
			<v-chip outlined>
				{{userEmail}}
				<logout-button :google-login="isGoogle"></logout-button>
			</v-chip>

		</div>
	</v-app-bar>
</template>

<script lang="js">
    import Logout from "./../UserNavigation/App";
    import LangSwitcher from "./../LangSwitcher/App";
    import AppLogo from '__components/core/AppLogo/App';
    import LogoutButton from '__components/core/LogoutButton/App';
    import UserRoleMixin from "../../mixins/UserRoleMixin";

    export default {
        name: 'AppToolbar',
        components: {
            LangSwitcher,
            Logout,
            AppLogo,
            LogoutButton
        },
		mixins: [UserRoleMixin],
        data() {
            return {
                applicationName: process.env.VUE_APP_NAME + ' ' + process.env.APP_VERSION,
                drawer: null,
                inboxPooler: null,
                loadingInbox: false
            }
        },
        props: {
            menuItems: {
                type: Array,
                required: false,
                default: () => []
            },
        },
        computed: {
            userEmail: function() {
                return this.currentUser ? this.currentUser.email : '';
			},
            unreadCount: function () {
                return this.$store.getters['users/inboxUnreadCount']
            }
        },
        created() {
            const vm = this;
			this.init();
            this.$event.$on(this.$event.types.LOGIN, function () {
                vm.init();
            });
            this.$event.$on(this.$event.types.LOGOUT, function () {
                clearInterval(vm.inboxPooler)
            });
        },
        beforeDestroy() {
            clearInterval(this.inboxPooler)
        },
        methods: {
            init() {
                this.loadInbox();
                this.inboxPooler = setInterval(() => {
                    this.loadInbox();
                }, 120000);
            },
            loadInbox() {
                const vm = this;
                if (this.loadingInbox || !this.isLogged) {
                    return;
                }
                this.loadingInbox = true;
                this.$store.dispatch('users/inboxUnreadCount').finally(function () {
                    vm.loadingInbox = false;
                })
            },
            showSearch() {
                this.$event.$emit(this.$event.types.SHOW_SEARCH);
            },
			inboxReload() {
                this.$event.$emit(this.$event.types.RELOAD_INBOX)
			},
        },
    };
</script>
