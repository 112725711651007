import Axios from 'axios';
import { base } from 'config/base';
import { urls } from 'config/urls';
import AxiosConfig from 'config/axios.js';

/**
 * Service for handling Users module API requests.
 */
export default {
	search: function(text) {
		let config = AxiosConfig.getDefault();
		config.params = {
			query: text
		}
		return Axios.get(base.VUE_APP_BACKEND_BASE_URL + urls.SEARCH, config);
	},
	loadNext: function(url) {
		let config = AxiosConfig.getDefault();
		return Axios.get(url, config);
	}

};
