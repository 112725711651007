<template>
	<!-- DRAG -->
	<drag
		class="drag"
		:draggable="draggable"
		:transfer-data="{ group: 'issue', issue: issue}"
		@dragstart="dragging = 'issue'"
		@dragend="dragging = null"
	>
		<!-- GHOST IMAGE START -->
		<template slot="image">
			<v-chip class="ma-2">
				<v-avatar left>
					<issue-avatar :issue="issue" :size="15">

					</issue-avatar>
				</v-avatar>
				{{ issue.name }}
			</v-chip>
		</template>
		<!-- GHOST IMAGE END -->
		<v-list-item @click="click" :to="globalTo" class="border-bottom">
			<v-list-item-action v-if="hasCheckbox">
				<v-checkbox
					v-model="isComplete"
					color="primary"
					@change="toggle"
				></v-checkbox>
			</v-list-item-action>
			<v-list-item-avatar class="ml-2 mr-4" :size="30">
				<issue-avatar :issue="issue"></issue-avatar>
			</v-list-item-avatar>
			<v-list-item-content @click="routerTo" class="py-2">
				<v-list-item-title>{{ name }}</v-list-item-title>
				<v-list-item-subtitle class="caption" v-if="hasDeadLine">{{ deadline }}</v-list-item-subtitle>
			</v-list-item-content>

			<v-list-item-action class="my-0 justify-content-center">
				<v-list-item-action-text :class="classObj">{{status}}</v-list-item-action-text>
				<v-list-item-action-text v-if="isArchived" :class="classObj">Archived</v-list-item-action-text>
			</v-list-item-action>
		</v-list-item>
	</drag>
	<!-- DRAG -->
</template>

<script>
    import IssueAvatar from "../IssueAvatar/App";

    export default {
        name: 'IssueListItem',
        components: {IssueAvatar},
        data: function () {
            return {
                dragImage: null
            };
        },
        props: {
            issue: {},
            hasCheckbox: {
                type: Boolean,
                default: false
            },
            to: {
                default: ''
            },
            draggable: {
                type: Boolean,
				default: true
			}
        },
        computed: {
            globalTo: function () {
                return !this.hasCheckbox ? this.to : '';
            },
            name: function () {
                return this.issue.name;
            },
            hasDeadLine: function () {
                return !!this.issue.deadline;
            },
            deadline: function () {
                if (!this.hasDeadline) {
                    return '';
                }
                return this.$moment(this.issue.deadline).format('DD. MM. YYYY');
            },
            status: function () {
                return this.firstUpperCase(this.issue.status);
            },
            isArchived: function () {
                return this.issue.is_archived;
            },
            isActive: function () {
                return this.issue.status === 'active';
            },
            isComplete: {
                get: function () {
                    return this.issue.status === 'completed';
                },
                set: function () {

                }
            },
            classObj: function () {
                return {
                    'primary--text': this.isActive,
                    'success--text': this.isComplete
                };
            }
        },
        methods: {
            routerTo() {
                if(!this.hasCheckbox) {
                    return;
				}
                this.$router.push({
                    name: 'issueDirectoryIssue',
                    params: {id: this.issue.issue_directory.id, issueId: this.issue.id}
                });
            },
            click() {
                this.$emit('click');
            },
            toggle(value) {
                this.$store.dispatch('issues/toggleIssueStatus', {issue: this.issue});
            },
            firstUpperCase(string) {
                return string.charAt(0).toUpperCase() + string.slice(1);
            }
        }
    };
</script>

