<template>
	<v-app id="inspire">

		<app-toolbar></app-toolbar>
		<app-search></app-search>
		<app-navigation></app-navigation>
		<v-main class="almostBlack white--text">
			<keep-alive>
				<slot></slot>
			</keep-alive>
		</v-main>

		<AppFooter></AppFooter>
	</v-app>
</template>

<script>
    import AppNavigation from '__components/core/AppNavigation/App.vue';
    import AppToolbar from '__components/core/AppToolbar/App.vue';
    import AppFooter from '__components/core/AppFooter/App.vue';
    import AppSearch from '__components/core/AppSearch/App.vue';

    export default {
        name: "Default",
		components: {
            AppNavigation,
            AppToolbar,
            AppFooter,
			AppSearch
		}
    }
</script>
