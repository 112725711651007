<template>
	<div class="w-100">
		<router-link
			class="d-block body-2 white--text"
			:to="{ name: 'issueDirectory', params: { id: directory.id } }"
		>{{ directory.name }}</router-link>
	</div>
</template>

<script>
    import Draggable from 'vuedraggable'
    import IssueListItem from '__components/features/issues/IssueListItem/App';

    export default {
        name: "IssueDirectoriesItem",
        components: {
            Draggable,
            IssueListItem
        },
        props: ['directory'],
        data: function () {
            return {
                draggableData: [],
            }
        },
        methods: {
            openContext(event) {
                this.$emit('openContext', {event: event, item: this.directory});
            },
            added: function (event) {
                const vm = this;
                if (!event.hasOwnProperty('added')) {
                    return;
                }
                let subIssue = event.added.element;
                if (subIssue.hasOwnProperty('issue')) {
                    subIssue = subIssue.issue;
                }
                this.$store.dispatch('issues/addDirectorySubIssue', {
                    directoryId: this.id || this.directory.id,
                    subIssue: subIssue
                }).then(function () {
                    vm.draggableData = [];
                })
            },
        }
    }
</script>
