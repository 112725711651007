import * as mt from '../../mutations-types';
import {SET_DIRECTORY_AUTHORIZED_USERS} from "../../mutations-types";

export default {
	[mt.SET_DIRECTORIES]: (state, data) => {
		state.issueDirectories = data;
	},

	[mt.SET_CURRENT_DIRECTORY]: (state, data) => {
		state.currentDirectory = data;
	},

	[mt.SET_DIRECTORY_AUTHORIZED_USERS]: (state, data) => {
		const users = state.directoryAuthorizedUsers.filter(item => item.id !== data.id);
		users.push({ id: data.id, users: data.users});
		state.directoryAuthorizedUsers = users;
	},

	[mt.SET_CURRENT_ISSUE]: (state, data) => {
		state.currentIssue = data;
		if (state.currentDirectory) {
			state.currentDirectory.issues = state.currentDirectory.issues.map(
				function(issue) {
					if (data.id === issue.id) {
						return data;
					}
					issue.child_issues = issue.child_issues.map(function(child) {
						if (data.id === child.id) {
							return data;
						}
						return child;
					});
					return issue;
				}
			);
		}
	},

	[mt.RESET_CURRENT_ISSUE]: (state, data) => {
		state.currentIssue = data;
	},

	[mt.ADD_WATCHER]: (state, data) => {
		if (!state.currentIssue) {
			console.log('Current issue must be set to add watcher!');
			return;
		}
		state.currentIssue.watchers.push(data);
	},

	[mt.REMOVE_WATCHER]: (state, removedItem) => {
		if (!state.currentIssue) {
			console.log('Current issue must be set to remove watcher!');
			return;
		}
		for (let i = 0; i < state.currentIssue.watchers.length; i++) {
			let w = state.currentIssue.watchers[i];
			if (w.id === removedItem.id) {
				state.currentIssue.watchers.splice(i, 1);
			}
		}
	},

	[mt.ADD_SOLVER]: (state, data) => {
		if (!state.currentIssue) {
			console.log('Current issue must be set to add solver!');
			return;
		}
		state.currentIssue.solvers.push(data);
	},

	[mt.REMOVE_SOLVER]: (state, removedItem) => {
		if (!state.currentIssue) {
			console.log('Current issue must be set to remove solver!');
			return;
		}
		for (let i = 0; i < state.currentIssue.solvers.length; i++) {
			let s = state.currentIssue.solvers[i];
			if (s.id === removedItem.id) {
				state.currentIssue.solvers.splice(i, 1);
			}
		}
	},
	[mt.ADD_DIRECTORY_CACHE]: (state, data) => {
		state.directoryCache = state.directoryCache.filter(item => item.id !== data.id);
		state.directoryCache.push(data);
	},
};
