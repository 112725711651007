import * as mt from '__store/mutations-types.js';

/**
 * Vuex store mutations for Groups module.
 */
export default {
	[mt.SET_GROUPS]: (state, data) => {
		state.groups = data;
	},
}
