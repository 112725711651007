<template>
	<v-navigation-drawer v-model="drawer" app dark clipped class="nav overflow-visible" :width="width">
		<v-overlay :absolute="true" :value="loading">
			<v-progress-circular indeterminate color="primary"></v-progress-circular>
		</v-overlay>
		<IssueDirectories @loading="setLoading"></IssueDirectories>
	</v-navigation-drawer>
</template>

<script>
    import IssueDirectories from "__components/features/issues/IssueDirectories/App";

    export default {
        name: "AppNavigation",
        components: {IssueDirectories},
        props: {
            source: String,
        },
        data() {
            return {
                loading: true,
                drawer: null,
                width: 280,
                borderSize: 7
            };
        },

        methods: {
            setLoading(state) {
                this.loading = state;
			},
            setBorderWidth() {
                let i = this.$el.querySelector(
                    ".v-navigation-drawer__border"
                );
                i.style.width = this.borderSize + "px";
                i.style.cursor = "col-resize";
            },
            setContentPadding(width) {
                document.querySelector(".v-main").style.paddingLeft = width + "px";
            },
            setEvents() {
                const minSize = this.borderSize;
                const el = this.$el;
                const drawerBorder = el.querySelector(".v-navigation-drawer__border");
                const content = document.querySelector(".v-main");
                const vm = this;
                const direction = el.classList.contains("v-navigation-drawer--right")
                    ? "right"
                    : "left";

                function resize(e) {
                    e.preventDefault();
                    document.body.style.cursor = "col-resize";
                    let f = direction === "right"
                        ? document.body.scrollWidth - e.clientX
						: e.clientX;
					// set min width
					f = (f<100) ? 100 : f;
                    el.style.width = f + "px";
                    content.style.paddingLeft = f + "px";
                }

                drawerBorder.addEventListener(
                    "mousedown",
                    function (e) {
                        if (e.offsetX < minSize) {
                            el.style.transition = 'initial';
                            content.style.transition = '0.2s padding';
                            document.addEventListener("mousemove", resize, false);

                            document.addEventListener("mouseup", mouseUpListener, false);
                        }
                    },
                    false
                );

                function mouseUpListener() {
                    el.style.transition = '';
                    vm.width = el.style.width;
                    document.body.style.cursor = "";
                    document.removeEventListener("mousemove", resize, false);
                    document.removeEventListener("mouseup", mouseUpListener, false);
                }

            }
        },
        mounted() {
            this.setBorderWidth();
            this.setEvents();
            this.setContentPadding(this.width);
        }
    };

</script>
