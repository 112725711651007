<template>
	<div class="v-treeview" :class="classes">
		<directory-tree-node
			:active.sync="active"
			:expanded.sync="expanded"
			class="w-100"
			v-for="item in draggableData"
			:key="item.id"
			:item="item"
			:is-draggable="isDraggable"
			@toggle="onToggle"
			@node-right-click="rightUpEmit"
		></directory-tree-node>
	</div>
</template>

<script>
    import DirectoryTreeNode from "./DirectoryTreeNode";


    export default {
        name: "DirectoryTree",
        components: {
            DirectoryTreeNode
        },
        props: {
            items: {
                type: Array
            },
            hoverable: {
                type: Boolean,
                default: false
            },
            dense: {
                type: Boolean,
                default: false
            },
            dark: {
                type: Boolean,
                default: null
            },
            light: {
                type: Boolean,
                default: null
            },
            expanded: {
                type: Array,
                default: () => []
            },
            active: {
                type: Array,
                default: () => []
            },
            isDraggable: {
                type: Boolean,
                default: false
			}
        },
        data() {
            return {
                expandedData: []
            };
        },
        computed: {
            classes: function () {
                return {
                    'v-treeview--hoverable': this.hoverable,
                    'v-treeview--dense': this.dense,
                    'theme--dark': this.rootIsDark,
                    'theme--light': !this.rootIsDark,
                }
            },
            expandedComputed: {
                get: function () {
                    return this.expanded
                },
                set: function (expanded) {
                    this.$emit('expanded', expanded)
                }
            },
            rootId: function () {
                return null;
            },
            draggableData: {
                get: function () {
                    return this.items.sort((a, b) => a.name.localeCompare(b.name));
                },
                set: function () {

                }
            },
            appIsDark() {
                return this.$vuetify.theme.dark || false;
            },
            rootIsDark() {
                if (this.dark === true) {
                    // explicitly dark
                    return true;
                } else if (this.light === true) {
                    // explicitly light
                    return false;
                } else {
                    // inherit from v-app
                    return this.appIsDark;
                }
            },
        },
        methods: {
            onToggle: function (payload) {
                let expanded = [...this.expanded];
                if (!payload.open) {
                    expanded = expanded.filter(id => id !== payload.id);
                } else {
                    expanded.push(payload.id);
                }
                this.$emit('update:expanded', [...new Set(expanded)]);
            },
            rightUpEmit: function(payload) {
                this.$emit('node-right-click', payload);
            }
        }
    }
</script>

<style scoped>

</style>
