<template>
	<div>
		<!--Notifications-->
		<notifications group="app" />
	</div>
</template>

<script lang="js">
	import CoreDashboard from "@/libs/vappcore/src/vue/components/AppDashboard/mixins/CoreDashboardMixin.js";
  	import NotificationsConfig from 'config/notifications.js';

	export default {
		name: 'AppDashboard',
		mixins: [CoreDashboard],
		components: {
		},
		methods:  {
		    /**
			 * Shows flash messages using vue-notifications.
			 */
         	showMessages(messages) {
				let _this = this;
				messages.forEach(value => {
					let messageObj = NotificationsConfig.getDefaultMessage();
					messageObj = {...messageObj, ...value};
					_this.$notify(messageObj);
				});
          	},
          	/**
			 * If newVal is defined, it overrides key in object.
		 	 *
             * @param obj
           	 * @param key
           	 * @param newVal
           	 */
			override(obj, key, newVal) {
				if(typeof newVal !== 'undefined') {
					obj[key] = newVal;
				}
			}
		},
		mounted() {
			this.$event.$on(this.$event.types.OPEN_DIALOG, payload => this.openDialog(payload));
			this.$event.$on(this.$event.types.CLOSE_DIALOG, payload => this.closeDialog(payload));
			this.$event.$on(this.$event.types.NOTIFY_MESSAGES, mesasges => this.showMessages(mesasges));
		}
	};
</script>
