import * as mt from '../../mutations-types.js';
import Vue from 'vue';
export default {
	[mt.SET_CURRENT_USER]: (state, data) => {
		Vue.set(state, 'currentUser', data);
	},
	[mt.LOGIN_USER]: (state, data) => {
		state.token = data.token;
		Vue.set(state, 'currentUser', data.user);
	},
	[mt.CLEAN_TOKEN]: (state) => {
		state.token = '';
	},
}
