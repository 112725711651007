<template>
	<div v-if="$vuetify.breakpoint.smAndUp">
		<v-btn icon>
			<v-icon>mdi-export-variant</v-icon>
		</v-btn>
		<v-btn icon>
			<v-icon>mdi-delete-circle</v-icon>
		</v-btn>
		<v-btn icon>
			<v-icon>mdi-plus-circle</v-icon>
		</v-btn>
	</div>
</template>

<script>
	export default {
		name: "UserNavigation",
		data() {
			return {
				currentUser: '',
				isLogged: false
			}
		},
		watch: {
			isLogged(newValue, oldValue) {
				//console.log('watching isLogged');
				if (newValue !== oldValue) {
					this.$emit('logged', newValue)
				}
			}
		},
		created() {
			this.isLogged = !!localStorage.authToken;
			if (this.isLogged) {
				this.currentUser = JSON.parse(localStorage.currentUser).fullname + ' (' + JSON.parse(localStorage.currentUser).email + ')';
			}
		},
		methods: {

		}
	}
</script>

<style scoped>

</style>
