<template>
	<v-list-item two-line :link="false" :to="to">
		<v-list-item-avatar>
			<slot name="avatar"></slot>
		</v-list-item-avatar>
		<v-list-item-content style="min-width:240px;">
			<v-list-item-title class="text-truncate">
				<slot name="name"></slot>
			</v-list-item-title>
			<v-list-item-subtitle>
				<slot name="subline"></slot>
			</v-list-item-subtitle>
		</v-list-item-content>
		<v-list-item-icon>
			<slot name="icons"></slot>
		</v-list-item-icon>
	</v-list-item>
</template>

<script>
export default {
    props: {
        to: {
        }
    }
};
</script>
