<template>
	<v-tooltip bottom>
		<template v-slot:activator="{ on: tooltip }">

			<v-icon
				right
				v-on="{ ...tooltip }"
				@click="logout"
			>mdi-logout
			</v-icon>
		</template>
		<span>Logout</span>
	</v-tooltip>
</template>

<script>
    export default {
        name: "LogoutButton",
        props: {
            googleLogin: {
                type: Boolean,
                default: false
            }
        },
        methods: {
            logout() {
                const vm = this;
                if (this.googleLogin) {

                    this.$gAuth.signOut()
                        .then(() => {
                            vm.$store.dispatch('login/logout');
                        })
                        .catch(error => {
                            console.log(error);
                            vm.$event.$emit(vm.$event.types.NOTIFY_MESSAGES, [
                                {
                                    type: 'error',
                                    title: 'Error',
                                    text: 'Could not be logged out from Google'
                                }
                            ]);
                        }).finally(() => {

                    });
                } else {
                    this.$store.dispatch('login/logout');
                }
            },
        }
    }
</script>

<style scoped>

</style>
