import Vue from 'vue';
import Vuex from 'vuex';
import vuexI18n from 'vuex-i18n';
import mutations from '__store/mutations';
import StoreModuleUsers from '__store/modules/users/store';
import StoreModuleGroups from '__store/modules/groups/store';
import StoreModuleIssues from '__store/modules/issues/store';
import StoreModuleSearch from '__store/modules/search/store';
import StoreModuleLogin from '__store/modules/login/store';
import StoreModuleInbox from '__store/modules/inbox'
import createCache from 'vuex-cache';

// translations
import translationsEn from './locale/en.json';
import translationsCs from './locale/cs.json';

Vue.use(Vuex);

let state = ((languages) => ({
	curLanguage: languages.find(obj => obj.short === localStorage.currentLang) || languages[0],
	languages,
	dialogs: [],
}))(JSON.parse(process.env.VUE_APP_LANGUAGES));

const store = new Vuex.Store({
	state,
	mutations,
	modules: {
		users: StoreModuleUsers,
		groups: StoreModuleGroups,
		issues: StoreModuleIssues,
		search: StoreModuleSearch,
		login: StoreModuleLogin,
		inbox: StoreModuleInbox
	},
	plugins: [
		createCache({timeout: 30000})
	]
});

Vue.use(vuexI18n.plugin, store);

// add translations directly to the application
Vue.i18n.add('en', translationsEn);
Vue.i18n.add('cs', translationsCs);

//Force language to 'cs'
//TODO - move somewhere else
Vue.i18n.set('cs');

export default store;
