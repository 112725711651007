//Axios config

export default {
	/**
	 * Gets default axios config in JS object.
	 * @returns {{headers: {Accept: string, "Content-Type": string}}}
	 */
	getDefault: function() {
		let defaultHeader = {
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			}
		};

		if (localStorage.getItem('authToken')) {
			defaultHeader.headers.Authorization = 'Bearer ' + localStorage.getItem('authToken');
		}

		return defaultHeader;
	}
};
