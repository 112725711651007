import Vue from './config/vue/vue';
import App from './App.vue';
import Axios from 'axios';
import router from './routes';
import store from './store';
import Notification from 'vue-notification';
import Moment from 'vue-moment';
import './event-bus/EventBus';
import VueMeta from 'vue-meta'
import GAuth from 'vue-google-oauth2'
import VueDragDrop from './libs/vue-drag-drop/src/index.js';
import './sentry';
import vuetify from './plugins/vuetify';

import './styles/entry.js';


Vue.use(Notification);
Vue.use(Moment);
Vue.use(VueDragDrop);

// Vue.use(AsyncComputed);
Vue.use(VueMeta, {
	refreshOnceOnNavigation: true
});
Vue.use(GAuth, {
	clientId: '450887322890-ouc2ssk2gs9dkp5rei5qav9uj777436b.apps.googleusercontent.com',
	scope: 'profile email',
	prompt: 'select_account'
});

Vue.prototype.$http = Axios;

// init quill plugins
import './config/quill';

new Vue({
	store,
	router,
	vuetify,
	render: h => h(App)
}).$mount('#app');
