//Actions for Groups
import GroupApiService from "__services/groups/GroupApiService";
import {SET_GROUPS} from "../../mutations-types";

/**
 * Vuex store actions for Groups module.
 */
export default {

	/**
	 * Reloads groups in state.
	 *
	 * @param state
	 */
	reloadGroups(state) {
		GroupApiService.getGroups()
			.then(function(request) {
				state.commit(SET_GROUPS, request.data);
			})
			.catch(request => console.log(request));
	},

	/**
	 *
	 * @param context
	 * @returns {*|Getter<S, S>|Getter<S, R>|AxiosPromise<any>}
	 */
	searchGroups(context, payload) {
		return GroupApiService.searchGroups(payload.string);
	}

}
